import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getLanguageFromLocalStorage } from '../app/utils/i18n';
import LOCALE from '../gen/i18n.json';

export default function Index() {
  const { asPath, replace } = useRouter();

  const isInRedirectState = asPath.includes('#state=');
  const isRoot = ['/', ''].includes(asPath);

  useEffect(() => {
    // Wait for redirect state to be parsed
    if (!isInRedirectState && isRoot) {
      const cachedLanguage = getLanguageFromLocalStorage();
      replace(`/${cachedLanguage || LOCALE.defaultLocale}`);
    }
  }, [isInRedirectState, isRoot, replace]);

  return null;
}
